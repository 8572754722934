import { Fragment, useState } from "react";
import { useDataProvider, List, Datagrid, TextField, Button, Confirm, 
        NumberField, BooleanField, Filter, SelectInput, TextInput, BooleanInput } from "react-admin";
import { AlarmOn, AlarmOff } from '@material-ui/icons';

const DirectoryBulkActions = ({ permissions, basePath, selectedIds, resource }) => {
    const dataProvider = useDataProvider();

    const [enableOpen, setEnableOpen] = useState(false);
    const [disableOpen, setDisableOpen] = useState(false);

    const handleEnableClick = () => setEnableOpen(true);
    const handleEnableDialogClose = () => setEnableOpen(false);
    const handleEnableConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Enable"}, {}).then(results => (alert(results.data)));
        setEnableOpen(false);
    }

    const handleDisableClick = () => setDisableOpen(true);
    const handleDisableDialogClose = () => setDisableOpen(false);
    const handleDisableConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Disable"}, {}).then(results => (alert(results.data)));
        setDisableOpen(false);
    }
    
    return (Array.isArray(permissions) && permissions.length > 0 && permissions.includes('Super Admin')) ? (
        <Fragment>
            <Button label="Enable" onClick={handleEnableClick}><AlarmOn /></Button>
            <Confirm
            isOpen={enableOpen}
                title="Enable Directory"
                content="Are you sure you want to enable these directories?"
                onConfirm={handleEnableConfirm}
                onClose={handleEnableDialogClose}
            />
            <Button label="Disable" onClick={handleDisableClick}><AlarmOff /></Button>
            <Confirm
                isOpen={disableOpen}
                title="Disable Directory"
                content="Are you sure you want to disable these directories?"
                onConfirm={handleDisableConfirm}
                onClose={handleDisableDialogClose}
            />
        </Fragment>
    ) : null;
};

const DirectoryFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Alias" />
        <TextInput source="RegistrationCode" />
        <SelectInput label="Region" source="Region" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
        <BooleanInput source="Active" defaultValue={true} />
        <SelectInput source="Tenancy" choices={[
            {id: 'DEDICATED', name: 'DEDICATED'},
            {id: 'SHARED', name: 'SHARED'}
        ]} />
        <SelectInput label="MFA" source="MFA" choices={[
            {id: 'OneLogin', name: 'OneLogin'},
            {id: 'Azure', name: 'Azure'}
        ]} />
        <TextInput label="Directory Category" source="Category" />
    </Filter>
);

export const DirectoryList = ({ permissions, ...props }) => (
    <List {...props} filters={<DirectoryFilter />} sort={{ field: 'Alias', order: 'ASC' }} bulkActionButtons={<DirectoryBulkActions permissions={permissions} {...props}/>} >
        <Datagrid>
            <TextField label="Directory Id" source="id" />
            <TextField source="Alias" />
            <TextField source="DirectoryName" />
            <BooleanField source="Active" />
            <NumberField label="Available IPs" source="AvailableIps" />
            <TextField source="RegistrationCode" />
            <TextField source="Region" />
            <TextField source="Tenancy" />
            <TextField label="MFA" source="MFA" />
            <TextField label="Directory Category" source="Category" />
            <TextField source="ThinClientToken" />
        </Datagrid>
    </List>
);