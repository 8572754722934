import { Admin, Resource } from "react-admin";
import ComputerIcon from '@material-ui/icons/Computer';
import DevicesIcon from '@material-ui/icons/Devices';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BuildIcon from '@material-ui/icons/Build';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import GroupIcon from '@material-ui/icons/Group';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import { createTheme } from '@material-ui/core/styles';

import DataProvider from './Providers/dataProvider.js';
import Dashboard from './Pages/Dashboard.js';
import AuthProvider from './Providers/authProvider.js';
import LoginPage from './Pages/LoginPage.js'
import { WorkspaceList, WorkspaceShow } from './Resources/workspaces.js'
import { DirectoryList } from './Resources/directories.js'
import { BundleList } from './Resources/bundles.js'
import { ImageList } from './Resources/images.js'
import { ActionList } from './Resources/actions.js'
import { PendingList } from './Resources/pending.js'
import { MyWorkspaceList, MyWorkspaceShow } from "./Resources/myworkspace.js";
import { GroupList, GroupShow, GroupCreate, GroupEdit } from "./Resources/groups.js";
import { QuotaList } from "./Resources/quotas.js";

const myTheme = createTheme({
  palette: {
      primary: {
        light: '#8280b7',
        main: '#545487',
        dark: '#282b5a',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#8280b7',
        main: '#545487',
        dark: '#282b5a',
        contrastText: '#ffffff',
      },
  },
});

const PortalDashboard = (permissions) => {
  var dashboardList = [<Resource name="myworkspace" 
      options={{ label: 'My Workspaces' }} 
      icon={ComputerIcon} list={MyWorkspaceList} show={MyWorkspaceShow} />];

  if (Array.isArray(permissions) && permissions.length > 0) {
    if (permissions.includes('AllWorkspacesView') || permissions.includes('Super Admin')) {
      dashboardList.push(<Resource name="workspaces" 
          options={{ label: 'All Workspaces' }} 
          icon={DevicesIcon} list={WorkspaceList} show={WorkspaceShow}/>);
    };
    if (permissions.includes('ActionsView') || permissions.includes('Super Admin')) {
      dashboardList.push([<Resource name="actions" 
          options={{ label: 'Workspace Actions' }} 
          icon={BuildIcon} list={ActionList} />,
        <Resource name="pending" 
          options={{ label: 'Provisioning Actions' }} 
          icon={ReceiptIcon} list={PendingList} />]);
    };
    dashboardList.push([<Resource name="directories" 
        list={permissions.includes('DirectoriesView') || permissions.includes('Super Admin') ? DirectoryList : null} />]);
    dashboardList.push([<Resource name="bundles" 
        icon={DynamicFeedIcon} 
        list={permissions.includes('BundlesView') || permissions.includes('Super Admin') ? BundleList : null} />]);
    dashboardList.push([<Resource name="images" 
        icon={PhotoLibraryIcon}
        list={permissions.includes('ImagesView') || permissions.includes('Super Admin') ? ImageList : null} />]);
    if (permissions.includes('GroupsView') || permissions.includes('Super Admin')) {
      dashboardList.push(<Resource name="groups" 
          icon={GroupIcon} options={{ label: 'AD Groups' }} 
          list={GroupList} show={GroupShow} 
          create={permissions.includes('Super Admin') ? GroupCreate : null} 
          edit={permissions.includes('Super Admin') ? GroupEdit : null}
      />)
    };
    if (permissions.includes('QuotasView') || permissions.includes('Super Admin')) {
      dashboardList.push(<Resource name="quotas" 
          icon={CloudQueueIcon} options={{ label: 'Service Quotas' }} 
          list={QuotaList} />)
    };
  };

  return dashboardList;
}

const App = () => {
  return (
    <Admin 
      title="CloudFactory WorkSpaces Portal"
      dashboard={Dashboard} 
      authProvider={AuthProvider} 
      dataProvider={DataProvider}
      loginPage={LoginPage}
      theme={myTheme}
    >
        {permissions => PortalDashboard(permissions)}
    </Admin>
  )
};

export default App;