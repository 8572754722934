import { withStyles, createStyles, CardActions, 
    CardContent, Typography, Grid } from '@material-ui/core';
import LoginButton from './LoginButton.js';

const styles = () =>
    createStyles({
        button: {
            width: '100%',
        },
        title: {
            fontSize: 14,
        }
    });

const LoginForm = ({ classes }) => {
    return (
        <div>
            <CardContent className={classes.title} >
                <Grid container align="center" justify="center" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Maximus Workspaces Portal</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container align="center" justify="center" alignItems="center">
                    <Grid item>
                        {LoginButton('Azure-SSO')}
                    </Grid>
                </Grid>
            </CardActions>
        </div>
    );
}

export default (withStyles(styles)(LoginForm));