import * as React from "react";
import { List, Datagrid, TextField, DateField, Filter, SelectInput, TextInput  } from "react-admin";

const ActionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Workspace Id" source="workspace_id" />
        <SelectInput source="action" choices={[
            {id: 'Reboot', name: 'Reboot'},
            {id: 'Start', name: 'Start'},
            {id: 'Stop', name: 'Stop'},
            {id: 'Rebuild', name: 'Rebuild'},
            {id: 'Restore', name: 'Restore'},
            {id: 'Modify', name: 'Modify'},
            {id: 'Terminate', name: 'Terminate'},
        ]} />
        <SelectInput label="Region" source="region" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
        <TextInput label="Requested By" source="user" />
        <TextInput source="result" />
    </Filter>
);

export const ActionList = props => (
    <List {...props} sort={{ field: 'scheduledTime', order: 'DESC' }} filters={<ActionFilter />} bulkActionButtons={false} >
        <Datagrid>
            <TextField label="Workspace Id" source="workspace_id" />
            <TextField source="action" />
            <TextField source="region" />
            <TextField label="Requested By" source="user" />
            <DateField label="Time Scheduled" source="scheduledTime" showTime options={{timeZoneName: 'short'}} />
            <DateField label="Time Initiated" source="completedTime" showTime options={{timeZoneName: 'short'}} />
            <TextField source="result" />
        </Datagrid>
    </List>
);