import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Drawer, Paper, Typography } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, BooleanInput, TextInput, required,
    CheckboxGroupInput, useDataProvider, FormDataConsumer } from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const WorkspaceReportForm = (props) => {
    const dataProvider = useDataProvider();
    const onSubmit = values => {
        dataProvider.workspacereportaction(props.resource, {id: props.id, action: "Report", values: values}, {}).then(results => (alert(results.data)));
    };
    return (
        <Form
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }} // necessary for ArrayInput
            subscription={defaultSubscription} // don't redraw entire form each time one field changes
            key={props.version} // support for refresh button
            keepDirtyOnReinitialize
            initialValues={{ IsIssueNow: false, IsMaximusEquipment: false }}
            render={({handleSubmit, form, submitting, pristine, values, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <Paper style={{ padding: 16 }}>
                        <div>
                            <Typography variant="body1">Please fill out this form to report any issues you may be experiencing with your workspace.<br />
                                                        If you need immediate assistance, please also contact the Service Desk after completing the form.<br /></Typography>
                            <br />
                        </div>
                        <div>
                            <BooleanInput label="Are you currently experiencing an issue?" source="IsIssueNow"/>
                            <DateTimeInput label="When did the issue start?" source="IssueStart" />
                            <CheckboxGroupInput label="What symptom(s) are you experiencing (select all that apply)?" source="IssueList" choices={[
                                { id: 'BlackScreen', name: 'Black Screen'},
                                { id: 'Latency', name: 'Latency / Slowness'},
                                { id: 'Disconnects', name: 'Random Disconnects'},
                                { id: 'NoAccess', name: 'Cannot Access Application / URL'},
                                { id: 'WebRTC', name: 'Web-RTC Connection Error'},
                                { id: 'PhoneIconBlocked', name: 'Phone icon Blocked in Red'},
                                { id: 'Other', name: 'Other'},
                            ]}/>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => typeof formData.IssueList != "undefined" && formData.IssueList.includes("NoAccess") &&
                                    <TextInput label="What application or URL can you not access?" source="NoAccessDetail" fullWidth={true} />
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => typeof formData.IssueList != "undefined" && formData.IssueList.includes("Other") &&
                                    <TextInput label="Please describe the issue." source="OtherDetail" fullWidth={true} />
                                }
                            </FormDataConsumer>
                            <BooleanInput label="Are you using Maximus Owned Equipment?" source="IsMaximusEquipment"/>
                            <TextInput label="Who is your Internet Service Provider (ISP)?" source="ISP" fullWidth={true} validate={required()} />
                        </div>
                        <div>
                            < br/>
                            <Button label="Report Issue" disabled={submitting || pristine || invalid} onClick={handleSubmit}><ReportIcon /></Button>
                        </div>
                    </Paper>
                    <pre>{JSON.stringify(values, 0 , 2)}</pre>
                </form>
            )}
        />
    );
};

class WorkspaceReportButton extends Component {
    state = { showPanel: false };

    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        const { id } = this.props;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Report Issue">
                    <ReportIcon />
                </Button>
                <Drawer
                    anchor="top"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <WorkspaceReportForm id={id} resource="myworkspace" basePath="/myworkspace" />
                </Drawer>
            </Fragment>
        );
    }
}

export default connect()(WorkspaceReportButton);