import { useLogin } from 'react-admin';
import { Button } from '@material-ui/core';

export default function LoginButton(provider) {
  const login = useLogin();

  const handleLoginClick = provider => {
      login({provider: provider});
  };

  return (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      onClick={() => handleLoginClick(provider)}
      title="Login"
    >
      Login with {provider.replace(/-/g, ' ')}
    </Button>
  );
}