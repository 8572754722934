import React from 'react';
import { Typography, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import { Button, BooleanInput, TextInput, SelectInput, 
    useDataProvider, FormDataConsumer, useNotify, required, number } from 'react-admin';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const timeoutValidation = (value, allValues) => {
    if (!value) {
        return 'The timeout is required';
    }
    if (value < 1) {
        return 'Must be over 1';
    }
    if (value > 48) {
        return 'Must be less than 48';
    }
    if (value % 1 !== 0) {
        return 'Must be whole number'
    }
    return '';
}

const validateComputeType = [required()]
const validateRunningMode = [required()]
const validateTimeout = [required(), number(), timeoutValidation];

export default function WorkspaceModifyButton(ids) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const selectedIds = ids.ids;

    const onSubmit = values => {
        dataProvider.bulkaction("workspaces", {data: values, action: "Modify", ids: selectedIds}, {}).then(results => (notify('Workspace Modification Request Submitted Successfully')));
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} label="Modify">
                <BuildIcon />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modify Workspace(s)</DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={onSubmit}
                        mutators={{ ...arrayMutators }} // necessary for ArrayInput
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        keepDirtyOnReinitialize
                        validate={values => {
                            const errors = {}
                            if (values.ChangeRunningMode && values.ChangeComputeType ) {
                                errors.ChangeRunningMode = 'Cannot change multiple properties'
                                errors.ChangeComputeType = 'Cannot change multiple properties'
                            }
                            return errors
                        }}
                        render={({handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <Typography variant="body2">Toggle which property you want to modify.<br />
                                                            Only one property can be changed at a time.</Typography><br />
                                    <BooleanInput label="Compute Type" source="ChangeComputeType"/>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.ChangeComputeType &&
                                            <SelectInput source="ComputeType" validate={validateComputeType} choices={[
                                                {id: 'VALUE', name: 'Value - 1 vCPU, 2 GiB Memory'},
                                                {id: 'STANDARD', name: 'Standard - 2 vCPU, 4GiB Memory'},
                                                {id: 'PERFORMANCE', name: 'Performance - 2 vCPU, 8GiB Memory'},
                                                {id: 'POWER', name: 'Power - 4 vCPU, 16GiB Memory'},
                                                {id: 'POWERPRO', name: 'PowerPro - 8 vCPU, 32GiB Memory'}
                                            ]} />
                                        }
                                    </FormDataConsumer>
                                    <BooleanInput label="Running Mode" source="ChangeRunningMode"/>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.ChangeRunningMode &&
                                            <SelectInput source="RunningMode" validate={validateRunningMode} choices={[
                                                {id: 'ALWAYS_ON', name: 'AlwaysOn'},
                                                {id: 'AUTO_STOP', name: 'AutoStop'}
                                            ]} />
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.RunningMode === 'AUTO_STOP' &&
                                            [<Typography variant="body2">Timeout in hours (minimum 1).</Typography>,
                                            <TextInput label="AutoStop Timeout" source="AutoStopTimeout" validate={validateTimeout}/>]
                                        }
                                    </FormDataConsumer>
                                </div>
                                    <div>
                                        < br/>
                                        <Button label="Modify" disabled={submitting || pristine} onClick={handleSubmit}><BuildIcon /></Button>
                                    </div>
                                <pre>{JSON.stringify(values, 0 , 2)}</pre>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}