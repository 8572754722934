import * as React from "react";
import { List, Datagrid, TextField, Filter, SelectInput } from "react-admin";

const QuotaFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Region" source="region" allowEmpty={false} alwaysOn={true} choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
    </Filter>
);

export const QuotaList = props => (
    <List {...props} sort={{ field: 'Name', order: 'DESC' }} filters={<QuotaFilter />} filterDefaultValues={{ region: "us-east-1" }} 
        bulkActionButtons={false} pagination={false} >
        <Datagrid>
            <TextField label="Service Limit" source="Name" />
            <TextField source="Limit" />
            <TextField label="Current Usage" source="CurrentValue" />
            <TextField source="Remaining" />
        </Datagrid>
    </List>
);