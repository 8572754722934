import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Drawer, Paper, Typography } from '@material-ui/core';
import { SlowMotionVideo } from '@material-ui/icons';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, BooleanInput, ArrayInput, TextInput, 
    SimpleFormIterator, useDataProvider, FormDataConsumer } from 'react-admin';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

function displayResults(results) {
    let message = "";
    for (let key of Object.keys(results.data)) {
        message = message.concat(key,": ",results.data[key])
    }
    alert(message);
}

const TestRunForm = (props) => {
    const dataProvider = useDataProvider();
    const onSubmit = values => {
        dataProvider.testrunaction(props.resource, {id: props.id, action: "Testrun", values: values}, {}).then(results => (displayResults(results)));
    };
    return (
        <Form
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }} // necessary for ArrayInput
            subscription={defaultSubscription} // don't redraw entire form each time one field changes
            key={props.version} // support for refresh button
            keepDirtyOnReinitialize
            render={({handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <Paper style={{ padding: 16 }}>
                        <div>
                            <Typography variant="body2">Enable Dry Run to see the results of this rule.<br />
                                                        The provision/termination CSV will be placed in S3, <br />
                                                        but no actions will be taken.</Typography>
                            <BooleanInput label="Dry Run" source="dryrun"/>
                        </div>
                        <div>
                            <Typography variant="body2">Populate override lists in order to force a<br />
                                                        particular action for only specific users. <br />
                                                        Users should be valid targets for these actions <br />
                                                        (i.e. They must be a member of the target group).</Typography>
                            <BooleanInput label="Override" source="override"/>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.override &&
                                    [<ArrayInput source="ProvisionOverride">
                                        <SimpleFormIterator>
                                            <TextInput label="UserID" source="UserID" />
                                        </SimpleFormIterator>
                                    </ArrayInput>,
                                    <ArrayInput source="TerminationOverride">
                                        <SimpleFormIterator>
                                            <TextInput label="UserID" source="UserID" />
                                        </SimpleFormIterator>
                                    </ArrayInput>]
                                }
                            </FormDataConsumer>
                        </div>
                        <div>
                            <Button label="Execute Custom" disabled={submitting || pristine} onClick={handleSubmit}><SlowMotionVideo /></Button>
                        </div>
                    </Paper>
                    <pre>{JSON.stringify(values, 0 , 2)}</pre>
                </form>
            )}
        />
    );
};

class GroupTestRunButton extends Component {
    state = { showPanel: false };

    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        const { id } = this.props;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Custom Run">
                    <SlowMotionVideo />
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <TestRunForm id={id} resource="groups" basePath="/groups" />
                </Drawer>
            </Fragment>
        );
    }
}

export default connect()(GroupTestRunButton);