import * as React from "react";
import { Fragment, useState, cloneElement } from "react";
import { useForm } from "react-final-form";
import { useDataProvider, List, Datagrid, TextField, DateField, ArrayField,
    Show, TabbedShowLayout, Tab, Create, downloadCSV,
    Edit, SimpleForm, TextInput, SelectInput, 
    Button, Confirm, FormDataConsumer, regex,
    TopToolbar, EditButton, Filter, BooleanInput,
    Toolbar, SaveButton, required, useListContext,
    sanitizeListRestProps, CreateButton, ExportButton } from "react-admin";
import jsonExport from 'jsonexport/dist';
import Typography from '@material-ui/core/Typography';
import { AlarmOn, AlarmOff, PlayArrow, DeleteForever } from '@material-ui/icons';

import GroupTestRunButton from "./Helpers/GroupTestRunButton.js"
import GroupOperationButton from "./Helpers/GroupOperationButton.js"
import GroupTermButton from "./Helpers/GroupTermButton.js"

const GroupBulkActions = ({ permissions, basePath, selectedIds, resource }) => {
    const dataProvider = useDataProvider();

    const [enableOpen, setEnableOpen] = useState(false);
    const [disableOpen, setDisableOpen] = useState(false);
    const [runOpen, setRunOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleEnableClick = () => setEnableOpen(true);
    const handleEnableDialogClose = () => setEnableOpen(false);
    const handleEnableConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Enable"}, {}).then(results => (alert(results.data)));
        setEnableOpen(false);
    }

    const handleDisableClick = () => setDisableOpen(true);
    const handleDisableDialogClose = () => setDisableOpen(false);
    const handleDisableConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Disable"}, {}).then(results => (alert(results.data)));
        setDisableOpen(false);
    }

    const handleRunClick = () => setRunOpen(true);
    const handleRunDialogClose = () => setRunOpen(false);
    const handleRunConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Run"}, {}).then(results => (alert(results.data)));
        setRunOpen(false);
    }

    const handleDeleteClick = () => setDeleteOpen(true);
    const handleDeleteDialogClose = () => setDeleteOpen(false);
    const handleDeleteConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Delete"}, {}).then(results => (alert(results.data)));
        setDeleteOpen(false);
    }
    
    return (Array.isArray(permissions) && permissions.length > 0 && permissions.includes('Super Admin')) ? (
        <Fragment>
            <Button label="Enable" onClick={handleEnableClick}><AlarmOn /></Button>
            <Confirm
            isOpen={enableOpen}
                title="Enable Group"
                content="Are you sure you want to enable these groups?"
                onConfirm={handleEnableConfirm}
                onClose={handleEnableDialogClose}
            />
            <Button label="Disable" onClick={handleDisableClick}><AlarmOff /></Button>
            <Confirm
                isOpen={disableOpen}
                title="Disable Group"
                content="Are you sure you want to disable these groups?"
                onConfirm={handleDisableConfirm}
                onClose={handleDisableDialogClose}
            />
            <Button label="Run Now" onClick={handleRunClick}><PlayArrow /></Button>
            <Confirm
                isOpen={runOpen}
                title="Execute Automation"
                content="Are you sure you want to execute automation for these groups?"
                onConfirm={handleRunConfirm}
                onClose={handleRunDialogClose}
            />
            <GroupTermButton ids = {selectedIds}/>
            <Button label="Delete" onClick={handleDeleteClick}><DeleteForever /></Button>
            <Confirm
                isOpen={deleteOpen}
                title="Delete Group"
                content="Are you sure you want to delete this group? (This will not terminate workspaces provisioned under this group)"
                onConfirm={handleDeleteConfirm}
                onClose={handleDeleteDialogClose}
            />
        </Fragment>
    ) : null;
};

const GroupFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Group Friendly Name" source="WorkspaceGroupFriendlyName" />
        <SelectInput label="State" source="State" choices={[
            {id: 'ENABLED', name: 'ENABLED'},
            {id: 'DISABLED', name: 'DISABLED'}
        ]} />
        <TextInput label="Project Name" source="ProjectName" />
        <TextInput label="Charge Code" source="ProjectCostCode" />
        <TextInput label="Directory Category" source="DirectoryCategory" />
        <SelectInput label="Running Mode" source="RunningMode" choices={[
            {id: 'ALWAYS_ON', name: 'ALWAYS_ON'},
            {id: 'AUTO_STOP', name: 'AUTO_STOP'}
        ]} />
        <SelectInput label="Region" source="DirectoryRegion" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
    </Filter>
);

const ListActions = ({ permissions, ...props }) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();

    if (Array.isArray(permissions) && permissions.length > 0) {
        return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {permissions.includes('Super Admin') ? <CreateButton basePath={basePath} /> : null}
            {permissions.includes('Super Admin') ? <GroupOperationButton /> : null}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>);
    }
    return null;
};

const exporter = groups => {
    const groupsForExport = groups.map(group => {
        const { ExecutionLog, id, AutostopTimeout, ...groupsForExport } = group;
        return groupsForExport;
    });
    jsonExport(groupsForExport, {
        headers: ['ProjectName', 'ProjectCostCode', 'WorkspaceGroupFriendlyName','DirectoryRegion', 'State', 
            'WorkspaceGroupDn', 'DirectoryCategory', 'RunningMode', 'ComputeType', 'BundleId', 'UserVolumeSize', 
            'RootVolumeSize', 'LastRun'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'groups'); // download as 'groups.csv` file
    });
};

export const GroupList = ({ permissions, ...props }) => (
    <List {...props} sort={{ field: 'WorkspaceGroupFriendlyName', order: 'ASC' }} filters={<GroupFilter />} actions={<ListActions permissions={permissions}/>} 
        bulkActionButtons={<GroupBulkActions permissions={permissions} {...props}/>} exporter={exporter}>
        <Datagrid rowClick="show">
            <TextField label="Group Friendly Name" source="WorkspaceGroupFriendlyName" />
            <TextField label="State" source="State" />
            <DateField label="Last Start" source="LastRun" showTime options={{timeZoneName: 'short'}} />
            <TextField label="Project Name" source="ProjectName" />
            <TextField label="Charge Code" source="ProjectCostCode" />
            <TextField label="Directory Category" source="DirectoryCategory" />
            <TextField label="Running Mode" source="RunningMode" />
            <TextField label="Region" source="DirectoryRegion" />
        </Datagrid>
    </List>
);

const GroupShowActions = ({ basePath, data, resource, permissions }) => {
    return (Array.isArray(permissions) && permissions.length > 0 && permissions.includes('Super Admin')) ? (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <GroupTestRunButton id = {data.id}/>
    </TopToolbar>
    ): null;
};

const GroupTitle = ({ record }) => {
    return <span>Group {record ? `"${record.WorkspaceGroupFriendlyName}"` : ''}</span>;
};

export const GroupShow = ({permissions, ...props}) => (
    <Show {...props} title={<GroupTitle />} actions={<GroupShowActions permissions={permissions}/>} >
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField label="State" source="State" />
                <TextField label="Project Name" source="ProjectName" />
                <TextField label="Charge Code" source="ProjectCostCode" />
                <TextField label="AD Group DN" source="WorkspaceGroupDn" />
                <TextField label="Group Friendly Name" source="WorkspaceGroupFriendlyName" />
                <TextField label="Region" source="DirectoryRegion" />
            </Tab>
            <Tab label="Properties" path="properties">
                <TextField label="Directory Category" source="DirectoryCategory" />
                <TextField label="Directory Override" source="DirectoryOverride" emptyText="Not Applicable" />
                <TextField label="BundleId" source="BundleId" />
                <TextField label="Running Mode" source="RunningMode" />
                <TextField label="Autostop Timeout In Hours" source="AutostopTimeout" emptyText="Not Applicable" />
                <TextField label="Compute Type" source="ComputeType" />
                <TextField label="Root Volume Size (Gb)" source="RootVolumeSize" />
                <TextField label="User Volume Size (Gb)" source="UserVolumeSize" />
            </Tab>
            <Tab label="Execution History" path="history">
                <ArrayField label="Execution Log" source="ExecutionLog">
                    <Datagrid>
                        <DateField label="Completed Time" source="ExecutionTime" showTime options={{timeZoneName: 'short'}} />
                        <TextField source="ProvisionCount" />
                        <TextField source="TerminationCount" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const Aside = () => (
    <div style={{ width: 250, margin: '1em' }}>
        <Typography variant="h5">Group creation details</Typography>
        <Typography variant="body1">
            Submitting this form will instruct the Workspaces automation to begin provisioning and 
                terminating workspaces for users belonging to this group.
        </Typography>
        <Typography component="span" variant="body2">
            <dl>
                <dt>Project Name</dt>
                <dd>This value will be present on all Workspaces for this group as the 'ProjectName' tag</dd><br />
                <dt>Project Code Code</dt>
                <dd>This value will be present on all Workspaces for this group as the 'ProjectCostCode' tag</dd><br />
                <dt>Workspace Group DN</dt>
                <dd>The full AD Group distinguished name (DN) of the new group to onboard</dd><br />
            </dl>
        </Typography>
    </div>
);

const GroupNameParser = group_name => {
    let parsed_data = {
        ErrorMessage: "",
        project_name: "",
        region: ""
    }

    if (typeof(group_name) === "undefined") {
        parsed_data.ErrorMessage = "Waiting for valid AD group";
        return (parsed_data);
    }

    const group_split = group_name.split(" - ");
    if (group_split.length !== 3) {
        parsed_data.ErrorMessage = "Waiting for valid AD group";
        return (parsed_data);
    }

    if (group_split[0] !== "SG" || group_split[1] !== "WSC") {
        parsed_data.ErrorMessage = "Waiting for valid AD group";
        return (parsed_data);
    }

    const name_split = group_split[2].split(" ")
    if (name_split.length < 2) {
        parsed_data.ErrorMessage = "Waiting for valid AD group";
        return (parsed_data);
    }

    const region = name_split[name_split.length - 1]
    switch (region.toLowerCase()) {
        case "east":
            parsed_data.region = "us-east-1";
            break;
        case "west":
            parsed_data.region = "us-west-2";
            break;
        case "singapore":
            parsed_data.region = "ap-southeast-1";
            break;
        case "ireland":
            parsed_data.region = "eu-west-1";
            break;
        default:
            parsed_data.ErrorMessage = "Region is invalid";
            return (parsed_data);
    }

    parsed_data.project_name = group_split[2].replace(" " + region, '')
    return(parsed_data)
}

const GetParsedGroupText = (group_name, advanced) => {
    if (advanced || typeof(group_name) === 'undefined') {
        return;
    }

    let parsed_data = GroupNameParser(group_name);

    if(parsed_data.ErrorMessage !== "") {
        return (<Typography variant="caption" key="caption3" color="error">{parsed_data.ErrorMessage}</Typography>)
    }
    return (<Typography variant="caption" key="caption3">
                    Project Name: {parsed_data.project_name}<br />
                    Region: {parsed_data.region}</Typography>)
}

const UpdateAdvancedFormFields = (form, group_name, advanced) => {
    if (advanced) {
        form.change('ProjectName', null)
        form.change('DirectoryRegion', null)
        form.change('WorkspaceGroupDn', null)
        return;
    }

    let parsed_data = GroupNameParser(group_name);

    if(parsed_data.ErrorMessage !== "") {
        return;
    }

    form.change('ProjectName', parsed_data.project_name)
    form.change('DirectoryRegion', parsed_data.region)
    form.change('WorkspaceGroupDn', "CN="+group_name+",OU=Managed Groups,OU=WorkSpaces,OU=AWS,OU=Infrastructure,OU=Security Groups,DC=MAXCORP,DC=MAXIMUS")

    return;
}

const validateDirectoryOverride = regex(/^(d-[a-z0-9]{10},?)*$/,"Must be a comma seperated list of valid directory IDs (no spaces)")

const GroupCreateForm = ({ formData, ...rest }) => {
    const form = useForm();

    return (
        <Fragment>
            <SelectInput label="Directory Category" source="DirectoryCategory" validate={required()} choices={[
                        {id: 'prod', name: 'Production (Default)'},
                        {id: 'prod-dedicated', name: 'Production (BYOL)'},
                        {id: 'prod-dedicated-azure', name: 'Production (BYOL - Azure MFA)'},
                        {id: 'prod-it', name: 'Production (IT)'},
                        {id: 'prod-it-linux', name: 'Production (IT Linux)'},
                        {id: 'prod-resolver-bypass', name: 'Production (Route53 Resolver Bypass)'},
                        {id: 'prod-linux', name: 'Linux'},
                        {id: 'AL_P-EBT', name: 'AL_P-EBT'},
                        {id: 'cco', name: 'CCO'},
                        {id: 'cms', name: 'CMS'},
                        {id: 'eaos', name: 'EAOS'},
                        {id: 'fed', name: 'Federal'},
                        {id: 'fema', name: 'FEMA'},
                        {id: 'mia', name: 'MIA'},
                        {id: 'vax', name: 'VAX'}
                      ]} /><span >    </span>
            <SelectInput label="Workspace Type" source="ComputeType" validate={required()} choices={[
                        {id: 'PERFORMANCE', name: 'Performance'},
                        {id: 'POWER', name: 'Power'},
                        {id: 'POWERPRO', name: 'PowerPro'},
                        {id: 'STANDARD', name: 'Standard'},
                        {id: 'VALUE', name: 'Value'}
                    ]} /><span >    </span>
            <TextInput label="Cost Code" source="ProjectCostCode" validate={required()}/><br />
            <FormDataConsumer key="basicform">
                {({ formData, ...rest }) => 
                    [<Typography variant="caption" key="caption1">The AD group must be in "OU=Managed Groups,OU=WorkSpaces,OU=AWS,OU=Infrastructure,OU=Security Groups,DC=MAXCORP,DC=MAXIMUS".<br />
                                        For other DNs, or to customize any other fields, use the "Advanced" selector below.</Typography>,
                    <TextInput disabled={formData.advanced} fullWidth={true} label="Workspace Group" source="WorkspaceGroupFriendlyName" key="friendlygroup"
                            initialValue="SG - WSC - <Project Name> <Region>"/>,
                    GetParsedGroupText(formData.WorkspaceGroupFriendlyName, formData.advanced)]
                }
            </FormDataConsumer>
            <br />
            <BooleanInput label="Advanced" onChange={value => UpdateAdvancedFormFields(form, formData.WorkspaceGroupFriendlyName, formData.advanced)} source="advanced"/>
            <FormDataConsumer key="advanceform">
                {({ formData, ...rest }) => formData.advanced &&
                    [<TextInput label="Project Name" source="ProjectName" key="projectinput" />, <span key="projectspan">    </span>,
                    <SelectInput label="Region" source="DirectoryRegion" key="regioninput" choices={[
                        {id: 'us-east-1', name: 'us-east-1'},
                        {id: 'us-west-2', name: 'us-west-2'},
                        {id: 'eu-west-1', name: 'eu-west-1'},
                        {id: 'ap-southeast-1', name: 'ap-southeast-1'}
                    ]} />, <br key="regionbreak"/>,
                    <Typography variant="caption" key="caption2">Example Workspace Group DN:<br />
                                    CN=SG - WSC - IT Services East,OU=Managed Groups,OU=WorkSpaces,OU=AWS,OU=Infrastructure,OU=Security Groups,DC=MAXCORP,DC=MAXIMUS</Typography>,<br key="groupbreak"/>,
                    <TextInput label="Workspace Group DN" source="WorkspaceGroupDn" key="groupinput" fullWidth={true} />,
                    <BooleanInput label="Directory Override" source="SetDirectoryOverride" key="directoryoverrideinput"/>,
                    <FormDataConsumer key="directoryoverrideform">
                        {({ formData, ...rest }) => formData.SetDirectoryOverride &&
                            [<Typography variant="caption" key="directorydesc">Comma seperated list of DirectoryIds to use for provisioning.</Typography>,
                            <TextInput label="DirectoryIds" source="DirectoryOverride" key="directoryinput"  fullWidth={true} validate={validateDirectoryOverride}/>]
                        }
                    </FormDataConsumer>
                    ]
                }
             </FormDataConsumer>
        </Fragment>
    );
}

const ValidateForm = data => {
    if(data.advanced) {
        let group_split = data.WorkspaceGroupDn.split(',')
        data.WorkspaceGroupFriendlyName = group_split[0].replace('CN=', '')
        return data
    }

    let parsed_data = GroupNameParser(data.WorkspaceGroupFriendlyName)
    if(parsed_data.ErrorMessage !== "") {
        return data
    }
    
    data.ProjectName = parsed_data.project_name
    data.DirectoryRegion = parsed_data.region
    data.WorkspaceGroupDn = "CN="+data.WorkspaceGroupFriendlyName+",OU=Managed Groups,OU=WorkSpaces,OU=AWS,OU=Infrastructure,OU=Security Groups,DC=MAXCORP,DC=MAXIMUS"

    return data
}

const GroupCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton transform={data => ValidateForm(data)}/>
    </Toolbar>
)

export const GroupCreate = props => (
    <Create aside={<Aside />} {...props}>
        <SimpleForm redirect="list" warnWhenUnsavedChanges toolbar={<GroupCreateToolbar/>}>
            <FormDataConsumer>
                {formDataProps => (
                    <GroupCreateForm {...formDataProps} />
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export const GroupEdit = props => (
    <Edit {...props}>
        <SimpleForm warnWhenUnsavedChanges >
            <TextInput label="Project Name" source="ProjectName" />
            <TextInput label="Project Cost Code" source="ProjectCostCode" />
            <TextInput disabled label="Workspace Group DN" source="WorkspaceGroupDn" fullWidth={true} />
            <TextInput disabled label="Workspace Group Friendly Name" source="WorkspaceGroupFriendlyName" fullWidth={true} />
            <SelectInput label="Directory Category" source="DirectoryCategory" choices={[
                {id: 'prod', name: 'Production (Default)'},
                {id: 'prod-dedicated', name: 'Production (BYOL)'},
                {id: 'prod-dedicated-azure', name: 'Production (BYOL - Azure MFA)'},
                {id: 'prod-it', name: 'Production (IT)'},
                {id: 'prod-it-linux', name: 'Production (IT Linux)'},
                {id: 'prod-resolver-bypass', name: 'Production (Route53 Resolver Bypass)'},
                {id: 'prod-linux', name: 'Linux'},
                {id: 'AL_P-EBT', name: 'AL_P-EBT'},
                {id: 'cco', name: 'CCO'},
                {id: 'cms', name: 'CMS'},
                {id: 'eaos', name: 'EAOS'},
                {id: 'fed', name: 'Federal'},
                {id: 'fema', name: 'FEMA'},
                {id: 'mia', name: 'MIA'},
                {id: 'vax', name: 'VAX'}
            ]} />
            <TextInput label="DirectoryOverride" source="DirectoryOverride" fullWidth={true} validate={validateDirectoryOverride} />
            <SelectInput label="Region" source="DirectoryRegion" choices={[
                {id: 'us-east-1', name: 'us-east-1'},
                {id: 'us-west-2', name: 'us-west-2'},
                {id: 'eu-west-1', name: 'eu-west-1'},
                {id: 'ap-southeast-1', name: 'ap-southeast-1'}
            ]} />
            <SelectInput label="Compute Type" source="ComputeType" choices={[
                {id: 'PERFORMANCE', name: 'Performance'},
                {id: 'POWER', name: 'Power'},
                {id: 'POWERPRO', name: 'PowerPro'},
                {id: 'STANDARD', name: 'Standard'},
                {id: 'VALUE', name: 'Value'}
            ]} />
            <TextInput label="Bundle ID" source="BundleId" />
            <SelectInput label="Running Mode" source="RunningMode" choices={[
                {id: 'ALWAYS_ON', name: 'ALWAYS_ON'},
                {id: 'AUTO_STOP', name: 'AUTO_STOP'}
            ]} />
            <FormDataConsumer>
                 {({ formData, ...rest }) => formData.RunningMode === "AUTO_STOP" &&
                      <TextInput label="Autostop Timeout In Hours" source="AutostopTimeout" {...rest} />
                 }
             </FormDataConsumer>
            <TextInput label="Root Volume Size (Gb)" source="RootVolumeSize" />
            <TextInput label="User Volume Size (Gb)" source="UserVolumeSize" />
        </SimpleForm>
    </Edit>
);