import { List, Show, TextField, ChipField, TabbedShowLayout, Tab, 
        ReferenceField, useListContext, ShowButton, Create, 
        SimpleForm, TextInput, SelectInput, DateField} from "react-admin";
import { Card, CardActions, CardContent, CardHeader, Avatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { WorkspaceShowActions } from './Helpers/workspaceShowActions.js';
import { Computer } from '@material-ui/icons';

import WorkspaceReportButton from "./Helpers/WorkspaceReportButton.js"

const cardStyle = {
    width: 350,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const MyWorkspaceGrid = () => {
    const { ids, data, basePath } = useListContext();

    return (
        <div style={{ margin: '1em' }}>
        {ids.map(id =>
            <Card key={id} style={cardStyle}>
                <CardHeader
                    title={<TextField record={data[id]} source="UserName" />}
                    subheader={<TextField record={data[id]} source="ComputerName" />}
                    avatar={<Avatar><Computer /></Avatar>} 
                />
                <CardContent>
                    Operating System:&nbsp;&nbsp;&nbsp;
                    <TextField record={data[id]} source="WorkspaceProperties_OperatingSystemName" /><br />
                    Compute Type:&nbsp;&nbsp;&nbsp;
                    <TextField record={data[id]} source="WorkspaceProperties_ComputeTypeName" /><br />
                    Project Name:&nbsp;&nbsp;&nbsp;
                    <TextField record={data[id]} source="ProjectName" /><br />
                    Region:&nbsp;&nbsp;&nbsp;
                    <TextField record={data[id]} source="region" /><br /><br />
                    Workspace State:&nbsp;&nbsp;&nbsp;<ChipField record={data[id]} source="State" /><br />
                    Last Login Time:&nbsp;&nbsp;&nbsp;<DateField record={data[id]} source="LoginTime" showTime options={{timeZoneName: 'short'}}/><br />
                    Registration Code:&nbsp;&nbsp;&nbsp;
                    <ReferenceField record={data[id]} resource="myworkspace" source="DirectoryId" reference="directories" link={false} basePath={basePath}>
                        <TextField source="RegistrationCode" />
                    </ReferenceField>
                </CardContent>
                <CardActions style={{ textAlign: 'right' }}>
                    <ShowButton resource="myworkspace" basePath={basePath} record={data[id]} />
                    <WorkspaceReportButton id = {id}/>
                </CardActions>
            </Card>
        )}
        </div>
    );
};

export const MyWorkspaceList = props => (
    <List title="My Workspaces" {...props} pagination={false} exporter={false}>
        <MyWorkspaceGrid />
    </List>
);

export const MyWorkspaceShow = ({permissions, ...props}) => (
    <Show {...props} title="My Workspace" actions={<WorkspaceShowActions />}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="ComputerName" />
                <TextField label="IP Address" source="IpAddress" />
                <TextField source="UserName" />
                <TextField label="Project Name" source="ProjectName" />
                <TextField source="region" />
                <ChipField label="WorkSpace State" source="State" />
                <DateField label="Last Login Time" source="LoginTime" showTime options={{timeZoneName: 'short'}}/>
                <ReferenceField label="Registration Code" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="RegistrationCode" />
                </ReferenceField>
                <ReferenceField label="Thin Client Token" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="ThinClientToken" emptyText="Not Applicable"/>
                </ReferenceField>
            </Tab>
            {(Array.isArray(permissions) && permissions.length > 0 && permissions.includes('Self Admin')) &&
            <Tab label="Properties" path="properties">
                <TextField label="WorkSpace Id" source="id" />
                <TextField source="BundleId" />
                <TextField label="Running Mode" source="WorkspaceProperties_RunningMode" />
                <TextField label="Autostop Timeout In Minutes" source="WorkspaceProperties_RunningModeAutoStopTimeoutInMinutes" emptyText="Not Applicable" />
                <TextField label="Compute Type" source="WorkspaceProperties_ComputeTypeName" />
                <TextField label="Protocol" source="WorkspaceProperties_Protocols" />
                <TextField label="Root Volume Size (Gb)" source="WorkspaceProperties_RootVolumeSizeGib" />
                <TextField label="User Volume Size (Gb)" source="WorkspaceProperties_UserVolumeSizeGib" />
                <TextField label="Operating System" source="WorkspaceProperties_OperatingSystemName" />
            </Tab>}
            {(Array.isArray(permissions) && permissions.length > 0 && permissions.includes('Self Admin')) &&
            <Tab label="Directory" path="directory">
                <TextField source="DirectoryId" />
                <ReferenceField label="Directory Name" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="Alias" />
                </ReferenceField>
                <ReferenceField label="Directory Category" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="Category"/>
                </ReferenceField>
                <ReferenceField label="Tenancy" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="Tenancy" />
                </ReferenceField>
            </Tab>}
        </TabbedShowLayout>
    </Show>
);

const Aside = () => (
    <div style={{ width: 300, margin: '1em' }}>
        <Typography variant="h5">New Workspace Request Form</Typography>
        <Typography variant="body1">
            <p>Complete this form to request an Amazon Workspace be provisioned for your use.</p>
            <p>Upon submission, an approval email will be sent to your manager before provisioning can begin.</p>
        </Typography>
    </div>
);

const validateWorkspaceCreation = (values) => {
    const errors = {};
    if (!values.UserName) {
        errors.UserName = ['The User Name is required'];
    }
    return errors
};

export const MyWorkspaceCreate = props => (
    <Create aside={<Aside />} title={"Request Workspace"} {...props}>
        <SimpleForm validate={validateWorkspaceCreation} warnWhenUnsavedChanges >
            <TextInput source="UserName" />
            <SelectInput label="Operating System" source="OS" choices={[
                {id: 'Windows', name: 'Windows'},
                {id: 'Linux', name: 'Linux'}
            ]} />
        </SimpleForm>
    </Create>
);