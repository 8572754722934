import Amplify, { API, Auth } from 'aws-amplify';
import { GetFullList } from './GetFullList.js';
import { HttpError } from 'react-admin';

const apiName = process.env.REACT_APP_API_NAME;

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          scope: ['email', 'openid', 'profile'],
          redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
          redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL,
          responseType: "token"
        }
      },
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_API_NAME,
                endpoint: process.env.REACT_APP_API_URL,
            }
        ]
    }
  });

async function createFunction(resource, params) {
    return Auth.currentAuthenticatedUser().then(user => {
        const path = `/${resource}`;
        const token = user.signInUserSession.idToken.jwtToken;
        const myInit = {
            body: JSON.stringify(params.data),
            headers: { Authorization: `Bearer ${token}` }
        }
        return API.post(apiName, path, myInit);
    }).catch(err => { 
        console.log(err);
        return { data: "Error getting authentication token. Please log back in and try again."}; 
    });
}

async function executeAction(resource, query, token) {
    const path = `/${resource}/action`;
    const myInit = {
        queryStringParameters: query,
        headers: { Authorization: `Bearer ${token}` }
    }
    return API.get(apiName, path, myInit);
}

async function runBulkActions(resource, params, token) {
    const path = `/${resource}/action`;
    const myInit = {
        body: JSON.stringify(params),
        headers: { Authorization: `Bearer ${token}` }
    }
    return API.put(apiName, path, myInit);
}

async function customExporter(resource, params, token) {
    const path = `/${resource}`;
    const { page } = params.pagination;
    const { field, order } = params.sort;
    let count = 0;
    let exportData = [];
    let query = {
        sort: JSON.stringify([field, order]),
        page: JSON.stringify([page + count, 5000]),
        filter: JSON.stringify(params.filter),
    };
    do {
        let myInit = {
            queryStringParameters: query,
            headers: { Authorization: `Bearer ${token}` }
        }
        let response = await API.get(apiName, path, myInit);
        count += 1;
        exportData = exportData.concat(response.Items)
        if (exportData.length >= response.Total) {
            break;
        } 
        query.page = JSON.stringify([page + count, 5000]);
        query.pit = response.Pit;
        query.search_after = JSON.stringify(response.SearchAfter)
    } while (true)
    return {
        data: exportData,
        total: exportData.length,
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {  
    getList: (resource, params) => {
        if(params.pagination.perPage >= 30000 && resource === "workspaces") {
            return Auth.currentAuthenticatedUser().then(user => {
                const token = user.signInUserSession.idToken.jwtToken;
                return customExporter(resource, params, token);
            });
        }
        else if(resource === "workspaces" || resource === "quotas") {
            return Auth.currentAuthenticatedUser().then(user => {
                const token = user.signInUserSession.idToken.jwtToken;
                const path = `/${resource}`;
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    page: JSON.stringify([page, perPage]),
                    filter: JSON.stringify(params.filter),
                };
                const myInit = {
                    queryStringParameters: query,
                    headers: { Authorization: `Bearer ${token}` }
                }
                return API.get(apiName, path, myInit);
            }).then( response => {
                return {
                    data: response.Items,
                    total: response.Total,
                    validUntil: new Date().setTime(new Date().getTime() + (5 * 60 * 1000))
                }
            }).catch(err => { 
                if ( err === "not authenticated") {
                    return Promise.reject(new HttpError(err, 401));
                }
                return Promise.reject(new HttpError(err));
            });
        }
        else {
            return Auth.currentAuthenticatedUser().then(user => {
                const token = user.signInUserSession.idToken.jwtToken;
                return GetFullList(resource, params, token);
            }).catch(err => { 
                if ( err === "not authenticated") {
                    return Promise.reject(new HttpError(err, 401));
                }
                return Promise.reject(new HttpError(err));
            });
        }
    },

    clearCache: () => {
        GetFullList('clearcache','','');
        return Promise.resolve({data : 'Cache Cleared'});
    },

    getOne: (resource, params) => {
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            const path = `/${resource}/show`;
            const myInit = {
                queryStringParameters: {id: params.id},
                headers: { Authorization: `Bearer ${token}` }
            };
        
            return API.get(apiName, path, myInit);
        }).then( response => {
            return {data: response.Item}
        }).catch(err => { 
            console.log(err);
            return {data: "Error getting authentication token. Please log back in and try again."}; 
        });
    },

    getMany: (resource, params) => {
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            const path = `/${resource}`;
            const myInit = {
                headers: { Authorization: `Bearer ${token}` }
            };
        
            return API.get(apiName, path, myInit);
        }).then( response => {
            return {
                data: response.Items,
                validUntil: new Date().setTime(new Date().getTime() + (10 * 1000))
            }
        }).catch(err => { 
            console.log(err);
            return {data: "Error getting authentication token. Please log back in and try again."}; 
        });
    },

    action: (resource, params) => {
        const query = {
            id: params.id,
            action: params.action
        };
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            return executeAction(resource, query, token).then( response => ({
                data: response
            }))
        });
    },

    bulkaction: async function (resource, params) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const response = await runBulkActions(resource, params, token);
        return ({
            data: response.Message
        });
    },

    workspacemodifyaction: (resource, params) => {
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            return runBulkActions(resource, params, token).then( response => ({
                data: response.Message
            }))
        });
    },

    workspacereportaction: (resource, params) => {
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            const path = `/${resource}`;
            const myInit = {
                body: JSON.stringify(params),
                headers: { Authorization: `Bearer ${token}` }
            }
            return API.put(apiName, path, myInit).then( response => ({
                data: response.Message
            }))
        });
    },

    testrunaction: (resource, params) => {
        let query = {
            id: params.id,
            action: params.action
        };
        if ( typeof params.values.dryrun !== 'undefined' ) {
            query['dryrun'] = params.values.dryrun
        }
        if ( typeof params.values.override !== 'undefined' ) {
            query['override'] = params.values.override
        }
        if ( typeof params.values.ProvisionOverride !== 'undefined' ) {
            let provisionOverride = [];
            for (let i = 0; i < params.values.ProvisionOverride.length; i++) {
                provisionOverride[i] = params.values.ProvisionOverride[i].UserID;
            }
            query['ProvisionOverride'] = provisionOverride.toString();
        }
        if ( typeof params.values.TerminationOverride !== 'undefined' ) {
            let terminationOverride = [];
            for (let i = 0; i < params.values.TerminationOverride.length; i++) {
                terminationOverride[i] = params.values.TerminationOverride[i].UserID;
            }
            query['TerminationOverride'] = terminationOverride.toString();
        }
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            return executeAction(resource, query, token).then( response => ({
                data: response
            }))
        });
    },

    groupoperationaction: (resource, params) => {
        let query = {
            action: params.action
        };
        if ( params.action === 'Operation' ) {
            if ( typeof params.values.provisionState !== 'undefined' ) {
                query['provisionState'] = params.values.provisionState
            }
            if ( typeof params.values.terminateState !== 'undefined' ) {
                query['terminateState'] = params.values.terminateState
            }
        }
        else if ( params.action === 'Terminate' ) {
            query['groupIds'] = params.values
        }
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            return executeAction(resource, query, token).then( response => ({data: response.Message}
            ))
        });
    },

    getManyReference: (resource, params) => {
        return {
            data: 'Function not supported'
        }
    },

    update: (resource, params) => {
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            const path = `/${resource}`;
            const myInit = {
                body: JSON.stringify(params.data),
                headers: { Authorization: `Bearer ${token}` }
            };
        
            return API.put(apiName, path, myInit);
        }).then( response => {
            return {data: response.Item}
        }).catch(err => { 
            console.log(err);
            return {data: "Error getting authentication token. Please log back in and try again."}; 
        });
    },

    updateMany: (resource, params) => {
        return {
            data: 'Function not supported'
        }
    },

    create: (resource, params) => {
        return createFunction(resource, params).then( response => {
            if (response.Error) {
                return Promise.reject(response.Error);
            }
            return {data: response.Item}
        });
    },

    delete: (resource, params) => {
        return Auth.currentAuthenticatedUser().then(user => {
            const token = user.signInUserSession.idToken.jwtToken;
            const path = `/${resource}`;
            const myInit = {
                body: JSON.stringify(params.id),
                headers: { Authorization: `Bearer ${token}` }
            };
        
            return API.del(apiName, path, myInit);
        }).then( response => {
            return {data: response.Item}
        }).catch(err => { 
            console.log(err);
            return {data: "Error getting authentication token. Please log back in and try again."}; 
        });
    },

    deleteMany: (resource, params) => {
        return {
            data: 'Function not supported'
        }
    },
};