import { List, Datagrid, TextField, Filter, SelectInput, TextInput  } from "react-admin";

const BundleFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name" />
        <SelectInput label="OS" source="OS" choices={[
            {id: 'LINUX', name: 'Linux'},
            {id: 'WINDOWS', name: 'Windows'}
        ]} />
        <TextInput source="ImageId" />
        <SelectInput label="Region" source="region" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
        <SelectInput label="Compute Type" source="ComputeType_Name" choices={[
            {id: 'PERFORMANCE', name: 'Performance'},
            {id: 'POWER', name: 'Power'},
            {id: 'POWERPRO', name: 'PowerPro'},
            {id: 'STANDARD', name: 'Standard'}
        ]} />
    </Filter>
);

export const BundleList = props => (
    <List {...props} filters={<BundleFilter />} bulkActionButtons={false} >
        <Datagrid>
            <TextField label="Bundle Id" source="id" />
            <TextField source="Name" />
            <TextField label="Operating System" source="OS" />
            <TextField source="ImageId" />
            <TextField source="region" />
            <TextField label="Default Root Storage" source="RootStorage.Capacity" />
            <TextField label="Default User Storage" source="UserStorage.Capacity" />
            <TextField label="Default Compute Type" source="ComputeType.Name" />
        </Datagrid>
    </List>
);